import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
  trialWarn: false,
  nullTargetWarn: true,
});
ScrollTrigger.config({
  limitCallbacks: true,
  immediateRender: true,
  ignoreMobileResize: true,
  normalizeScroll: true,
});

import { Loader } from "@googlemaps/js-api-loader";
import Swiper from "swiper";
import {
  Autoplay,
  EffectCoverflow,
  EffectCreative,
  EffectCreative,
  Mousewheel,
  Navigation,
  Pagination,
  Parallax,
  Thumbs,
} from "swiper/modules";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,

  Parallax,
  EffectCreative,
  Thumbs,
  EffectCoverflow,
]);
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import hideOnScroll from "hide-on-scroll";
hideOnScroll({
  navbarSelector: "nav",
  hidingClass: "hidden",
});
Splitting();
function animIntro() {
  const fx10Titles = [...document.querySelectorAll("[data-effect10]")];

  fx10Titles.forEach((title, i) => {
    gsap.set(fx10Titles[i].querySelectorAll(".word"), {
      opacity: 0,
      y: 50,

      filter: "blur(20px)",
    });

    gsap.to(fx10Titles[i].querySelectorAll(".word"), {
      ease: "power1.inOut",
      opacity: 1,
      filter: "blur(0px)",
      duration: 2,
      y: 0,
      stagger: { each: 0.1, from: "start" },
    });
  });
}

/****************************************************************************************************************** */
/**** LENIS SCROLL INIT */
/****************************************************************************************************************** */
let lenis;
const initSmoothScrolling = () => {
  // Instantiate the Lenis object with specified properties
  lenis = new Lenis({
    direction: "vertical",
    easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
    infinite: false,

    lerp: 0.0525, // Lower values create a smoother scroll effect
    smoothWheel: true, // Enables smooth scrolling for mouse wheel events
    normalizeScroll: true,
    smoothTouch: true,
  });

  // Update ScrollTrigger each time the user scrolls
  lenis.on("scroll", () => ScrollTrigger.update());

  // Define a function to run at each animation frame
  const scrollFn = (time) => {
    lenis.raf(time); // Run Lenis' requestAnimationFrame method
    requestAnimationFrame(scrollFn); // Recursively call scrollFn on each frame
  };
  // Start the animation frame loop
  requestAnimationFrame(scrollFn);
  gsap.ticker.add((time) => {
    lenis.raf(time * 1000);
  });

  gsap.ticker.lagSmoothing(0);

  //gsap.ticker.lagSmoothing(0);
};

function solveAnimation() {
  const logoJP = document.querySelector(".logoJP");

  const logoAnim = new gsap.timeline({
    paused: true,
    onStart: () => master.play(),
  })
    .fromTo(
      logoJP.querySelectorAll(".st0"),
      {
        delay: 1,
        opacity: 0,
        duration: 1.8,
        filter: "blur(15px)",
        y: 30,
      },
      {
        opacity: 1,
        y: 0,
        stagger: 0.05,
        ease: "power4.Out",
        filter: "blur(0px)",
        duration: 1.2,
      }
    )
    .to(
      logoJP,

      {
        scale: 1.1,
        ease: "power4.Out",
        duration: 5.2,
      },
      "<"
    );

  //
  logoAnim.play();
  const services = document.querySelector(".services");
  // const animTo = [...document.querySelectorAll(".animTo")];
  // animTo.forEach((el) => {
  new gsap.timeline({
    paused: true,
    scrollTrigger: {
      trigger: document.querySelector(".services"),
      start: "top center+=25%",
      end: "center top",
      scrub: false,
      markers: false,
    },
  }).fromTo(
    services.querySelectorAll(".animTo"),
    {
      opacity: 0,
      duration: 1.2,
      filter: "blur(5px)",
      x: 80,
    },
    {
      opacity: 1,
      x: 0,
      stagger: 0.05,
      ease: "power4.Out",
      filter: "blur(0px)",
      duration: 1.2,
    },
    "<50%"
  );
}

new gsap.timeline({
  paused: true,
  scrollTrigger: {
    trigger: ".homeIntro",
    start: "top top+=5%",
    end: "bottom top",
    scrub: true,
    markers: false,
  },
})
  .fromTo(
    ".shadow",
    {
      opacity: 0,
    },
    {
      opacity: 1,

      ease: "power4.Out",

      duration: 1.2,
    },
    "<"
  )
  .fromTo(
    ".navWrap",
    {
      backdropFilter: "blur(0px)",
    },
    {
      backdropFilter: "blur(8px)",

      ease: "power4.Out",

      duration: 1.2,
    },
    "<"
  )
  .fromTo(
    ".screen",
    { scale: 1, y: 0 },
    {
      scale: 0.95,
      ease: "power4.Out",

      duration: 1.2,
    },
    "<-50%"
  );

// });
function init() {
  initSmoothScrolling();
  solveAnimation();
}
init();
let mySwiper = [];
/*********************** */
function createSwiper() {
  const swipers = [...document.querySelectorAll(".swiper")];
  swipers.forEach((el) => {
    new Swiper(el, {
      modules: [Navigation, Pagination, Parallax, Mousewheel],
      mousewheel: true,
      effect: "slide",
      creativeEffect: {
        prev: {
          // will set `translateZ(-400px)` on previous slides
          translate: [0, 0, -400],
        },
        next: {
          // will set `translateX(100%)` on next slides
          translate: ["100%", 0, 0],
        },
      },

      parallax: true,
      speed: 900,
      ease: "power4.inOut",
      preloadImages: true,
      updateOnImagesReady: true,
      spaceBetween: 10,

      reverseDirection: false,

      preventClicks: false,
      watchSlidesProgress: true,
      preventInteractionOnTransition: true,
      slidesPerGroup: 1,
      watchOverflow: true,

      smoothTouch: true,
      smoothWheel: false,
      mousewheel: {
        forceToAxis: false,
        mousewheel: false,
      },
      freeMode: false,

      followFinger: true,
      autoplay: false,

      disableOnInteraction: false,
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },

      autoplay: false,

      grabCursor: true,
      breakpoints: {
        320: {
          slidesPerView: 1.4,
          centeredSlides: true,
          freeMode: false,
          spaceBetween: 10,
          followFinger: true,
        },

        1200: {
          centeredSlides: false,
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  });
}
/*****************************************************************************************/

/*****************************************************************************************/
createSwiper();

const links = [...document.querySelectorAll(".scrollNav")];
links.forEach((el) => {
  el.addEventListener("click", () => {
    lenis.scrollTo("#" + el.dataset.link);
  });
});

async function showMapa() {
  const loader = new Loader({
    apiKey: "AIzaSyCRxzmj4eleUyOyQLlz2j7FLxdXYQxmMKw",
    version: "weekly",
  });

  const myLatLng = { lat: 50.0867261, lng: 14.4248311 };

  const mapOptions = {
    center: myLatLng,
    zoom: 17,

    styles: [
      {
        featureType: "all",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#000000",
          },
        ],
      },
      {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "administrative.province",
        elementType: "all",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [
          {
            saturation: "-39",
          },
          {
            lightness: "35",
          },
          {
            gamma: "1.08",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
          {
            saturation: "0",
          },
        ],
      },
      {
        featureType: "landscape.man_made",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "10",
          },
        ],
      },
      {
        featureType: "landscape.man_made",
        elementType: "geometry.stroke",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-14",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "10",
          },
          {
            gamma: "2.26",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-3",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "54",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-7",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-2",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "100",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.stroke",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-100",
          },
        ],
      },
    ],
    mapTypeControl: false,
    streetViewControl: false,
    scaleControl: true,
    rotateControl: false,
  };
  const image = "../img/beachflag.png";
  loader.load().then(function (google) {
    const svgMarker = {
      path:
        "M5 8.97266C7.25 8.72363 9 6.81641 9 4.5C9 2.01465 6.98535 0 4.5 0C2.01465 0 0 2.01465 0 4.5C0 6.81641 1.75 8.72363 4 8.97266V23H5V8.97266Z",
      fillColor: "#07211f",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 3,
      anchor: new google.maps.Point(9, 23),
    };

    const map = new google.maps.Map(document.getElementById("map"), mapOptions);

    new google.maps.Marker({
      position: myLatLng,
      map,
      icon: svgMarker,
      title: "AK PRocházka s.r.o.",
    });
  });
}
const promis = document.getElementById("map");

if (promis) {
  showMapa();
}
animIntro();
